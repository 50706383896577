import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const StatusBanner = makeShortcode("StatusBanner");
const Checklist = makeShortcode("Checklist");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const Tout = makeShortcode("Tout");
const Box = makeShortcode("Box");
const Link = makeShortcode("Link");
const Callout = makeShortcode("Callout");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <StatusBanner warning mdxType="StatusBanner">
      <p>{`Funding for hackathons is currently closed.`}</p>
    </StatusBanner>
    <h1>{`Hackathon Sponsorship`}</h1>
    <p>{`Planning a hackathon that will leverage the features of
Dai and MakerDAO? You could be eligible for funding.`}</p>
    <h2>{`Eligible Hackathons`}</h2>
    <Checklist mdxType="Checklist">
      <p>{`Conservative sponsorship requirements `}{`(`}{`under 5,000 Dai`}{`)`}</p>
      <p>{`Located in, or targeting: Asia-Pacific `}{`(`}{`APAC`}{`)`}{`, Latin America `}{`(`}{`LATAM`}{`)`}{`, Eastern Europe, the Middle East, and Africa `}{`(`}{`SEEMEA`}{`)`}</p>
      <p>{`Demonstrate access to talent and attendees `}{`(`}{`through channels like chats, forums, and GitHub`}{`)`}</p>
      <p>{`Themes with a focus on governance, risk management, stability, derivatives, and privacy tooling`}</p>
      <p>{`Find the current hackathon challenge themes under the "Hackathons" label in the `}<a parentName="p" {...{
          "href": "https://github.com/makerdao/community/issues?q=is%3Aissue+is%3Aopen+label%3Ahackathons"
        }}>{`Community-Development GitHub`}</a></p>
    </Checklist>
    <p>{`If your hackathon meets the above criteria, please fill out this brief application.`}</p>
    <Aligner center mdxType="Aligner">
      <Button to="https://airtable.com/shrmEMdxtYDUKtEkU" mdxType="Button"> Submit Application</Button>
    </Aligner>
    <p>{`We'll review your application and let you know what your next steps are.`}</p>
    <h2>{`Additional Support`}</h2>
    <p>{`In addition to providing funding for sponsorship, we can help develop ideas for hackathon categories and projects, or we may participate as judges, speakers, and mentors.`}</p>
    <p><a parentName="p" {...{
        "href": "https://chat.makerdao.com/channel/community-development"
      }}>{`Ask us about this in our chat`}</a></p>
    <Tout image alt mdxType="Tout">
      <Box mdxType="Box">
        <h2>{`Development Grants`}</h2>
        <p>{`Hackathon winners are often fast-tracked for development grants.
Please encourage your hackers to apply.`}</p>
        <Link to="/funding/development-grants" mdxType="Link">Development Grants</Link>
      </Box>
      <Box mdxType="Box">
        <h2>{`Hackathon Guide`}</h2>
        <p>{`Use this guide to prepare for your first hackathon or help participants in your hackathon understand what to expect.`}</p>
        <Link to="/contribute/hackathons/guide-to-hacking/" mdxType="Link">Guide to Hacking</Link>
      </Box>
    </Tout>
    <Callout icon="question" mdxType="Callout">
      <p>{`If you have some insight you’d like to share, feel free to add to this guide.
You can find out how on our `}<a parentName="p" {...{
          "href": "/contribute/content/"
        }}>{`content page`}</a>{`.`}</p>
    </Callout>
    <h2>{`Planning a Hackathon`}</h2>
    <h3>{`Basics`}</h3>
    <Checklist mdxType="Checklist">
      <p>{`The name and date of the event`}</p>
      <p>{`The location of the event`}</p>
      <p>{`The number of expected participants`}</p>
      <p>{`Links to event details specific to the hackathon`}</p>
    </Checklist>
    <h3>{`Event Details`}</h3>
    <hr></hr>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The theme of the event, if any`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The amount for the prize pool`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Number of challenges/tracks`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Information about the challenges/tracks`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Prize distribution`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Number of judges, and their names`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Whether or not the winners will be promoted on social media`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Where the details of the bounty will be hosted `}{`(`}{`e.g., Gitcoin`}{`)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Where participants will be posting information about their hacks `}{`(`}{`e.g., Devpost`}{`)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Information about where we can find media coverage or summaries of the event and the projects after the event is over`}</p>
      </li>
    </ul>
    <h3>{`Sponsor-Specific Information`}</h3>
    <hr></hr>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Details about sponsorship packages and amounts`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A breakdown of what our sponsorship covers`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Whether or not you will be able to provide us with the contact information of hackathon participants`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Whether or not we're required to be physically present for the hackathon`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Whether or not we're required to join as speakers, judges, or mentors`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Whether or not a sponsor table will be available for us if we are physically present`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      